import { StorageService } from './../storage/storage.service';
import { VariablesService } from './../variables/variables.service';
import { CookieService } from '../cookie/cookie.service';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ICampaign } from '../../models/campaign.model';
import { ICurrency } from '../../models/currency.model';
import { Currency, EuropeanCountries, GulfCountries } from '../../models/currency-list';
import { IHost } from '../../models/host.model';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { IUser } from '../../models/user.model';
import { DayjsPipe } from '../../pipes/dayjs/dayjs.pipe';
import { StartAFrUrls } from '../../models/start-fr-urls';

declare let blitz: any;
/**
 * Utility service which provides commonly used
 * functions/medthods
 */
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  imageDomain = environment.APP.IMAGE_DOMAIN;
  defaultImg = '/assets/images/default-campaign-picture.jpg';
  defaultBlurImg = '/assets/images/defaultBlurImg.png';
  logoUrlDark = '/assets/images/logo-light-bg.svg';
  logoUrlLight = '/assets/images/logo-light-bg.svg';
  placeholderGif = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  /**Campaign Type  */
  campaignType = new BehaviorSubject<any>('');
  campaignType$ = this.campaignType.asObservable();
  /**International Tel Input variable*/
  number = new Subject<any>();
  number$ = this.number.asObservable();
  /**Get params from campaign*/
  param = new Subject<any>();
  param$ = this.param.asObservable();
  /**Get params from campaign*/
  url = new BehaviorSubject<any>(null);
  url$ = this.url.asObservable();
  /**Get params from campaign*/
  donorList = new BehaviorSubject<any>(null);
  donorList$ = this.donorList.asObservable();
  /**Get currency*/
  currency = new BehaviorSubject<any>(null);
  /**Site Url*/
  siteUrl = new Subject<any>();
  siteUrl$ = this.siteUrl.asObservable();
  /**Is Logged In*/
  isLoggedIn = new BehaviorSubject<any>(false);
  isLoggedIn$ = this.isLoggedIn.asObservable();
  /**Domain Details */
  domain_details: IHost;
  /**loading from browser or server*/
  isBrowser: boolean;
  /**Currecncies */
  currencies: ICurrency[] = Currency;
  europeanCountries = EuropeanCountries;
  gulfCountries = GulfCountries;
  /**Sent token to specific req*/
  isToken = true;
  /**Intl tel input error observable */
  checkIntlTelError = new BehaviorSubject<any>(false);

  bodyScrollTop: any;
  doc: any;

  utm_url_string: string = '';
  utm_url_obj: any;
  dayjsPipe = new DayjsPipe();
  startAFrUrls = StartAFrUrls;

  constructor(
    public router: Router,
    public actRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public titleService: Title,
    public vars: VariablesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    public cookie: CookieService,
    public storage: StorageService,
    private scriptLoaderService: ScriptLoaderService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.domain_details = {
        domain_name: window.location.hostname,
        full_url: window.location.href,
        domain_url: window.location.origin,
      };
      this.doc = document;
      this.setUtm();
      const qparams = this.getUrlParams();
      if (qparams?.utm_source?.match('internal_impact_calling') && !window.location.pathname.match('stories/') && !qparams?.to?.match('stories/')) {
        this.vars.isVariableLogin = true;
      }
    }
  }

  setUtm() {
    if (this.isBrowser) {
      this.utm_url_string = this.getUTMonly('url_string');
      this.utm_url_obj = this.getUTMonly();
    }
  }
  /**
   * Get the icon of the currency
   */
  getCurrencyIcon(currency: string): ICurrency {
    for (const item of this.currencies) {
      if (currency === item.currency) {
        return {
          code: item.code,
          is_symbol: item.is_symbol,
          icon: item.icon,
          currency: item.currency,
          symbol: item.symbol
        };
      }
    }
  }
  /**Native Navigation
   * @param url Complete url
   */
  nativeNavigation(url: string, in_new_tab?: boolean) {
    if (this.isBrowser) {
      if (this.utm_url_string && !url?.match('utm_')) {
        url += (url?.includes('?') ? '&' : '?') + this.utm_url_string;
      }
      if (in_new_tab) {
        window.open(url, '_blank');
      } else {
        document.location.href = url;
      }
    }
  }
  /**
   * Navigation medthod
   * @param path
   */
  navigate(path: string, param?: string) {
    param ?
      this.router.navigate([path, param], { queryParamsHandling: 'merge' }) :
      this.router.navigate([path], { queryParamsHandling: 'merge' });
  }
  /**Check is on mircro site */
  isMicroSite() {
    const name: string = this.domain_details.domain_name;
    if (name.match(/www.ketto.org|dev\d.ketto.org|localhost/gm)) {
      return false;
    } else {
      return name.match(/ketto.org/gm) ? true : false;
    }
  }
  /**Check is on White labeled site */
  isWLSite() {
    return this.domain_details.domain_name.match(/ketto.org|localhost/gm) ? false : true;
  }
  /**Get the parm query */
  getParamQuery(id: any) {
    return this.actRoute.snapshot.paramMap.get(id);
  }
  /**Get the origin of the page */
  getOrigin(name: string) {
    switch (name) {
      case 'campaign':
        return 'f';
      case 'stories':
        return 's';
      default:
        return 'f';
    }
  }
  /**File upload validation */
  onFileChange(event: any) {
    const regEx = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (regEx.exec(file.name)) {
        if (fileSize < 10) {
          return { message: 'Success', error: false };
        } else {
          return { message: 'File size exeeds 10MB', error: true };
        }
      } else {
        return { message: 'Unsupported file format', error: true };
      }
    }
  }
  /**Check controls validation on submit */
  checkValidForm(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  /**Format date according to the db requirement */
  formatDate(date: Date) {
    if (date) {
      const temp = date.toISOString().split('T');
      const d = temp[0];
      const t = temp[1].split('.')[0];
      return d.concat(' ' + t);
    }
  }

  formatDateToYYYYMMDD(dateObject: Date) {
    if (typeof dateObject === 'string') {
      return dateObject;
    }
    if (dateObject) {
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(dateObject.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    if (typeof dateObject === 'string') {
      return dateObject;
    }
  }
  /**
   * Snack bar for error notifications
   */
  openSnackBar(message: any, type: any, config?: MatSnackBarConfig) {
    this.zone.run(() => {
      const snackBarClass = type === 'success' ? 'snackbar-success' : 'snackbar-error';
      const matConfig: MatSnackBarConfig = {
        verticalPosition: config && config.verticalPosition ? config.verticalPosition : 'top',
        horizontalPosition: config && config.horizontalPosition ? config.horizontalPosition : 'center',
        panelClass: [snackBarClass],
        duration: config && config.duration ? config.duration : 10000
      };
      this.snackBar.open(message, 'DISMISS', matConfig).afterOpened().subscribe(() => {
        if (typeof document !== 'undefined') {
          const el: any = document.getElementsByTagName('snack-bar-container').item(0);
          el.parentElement.parentElement.style.zIndex = '1001';
        }
      });
    });
  }
  /**Start the similar fundraiser */
  startSimilar(fundraiser: any, type?: any) {
    let campaignType = type;
    if (!type) {
      campaignType = this.getCampaignTypeFromId(fundraiser.parent_cause_id);
    }
    const url = this.domain_details.domain_url + '/campaign/start_new_campaign.php?type=' + campaignType;
    this.nativeNavigation(url);
  }
  /**Set array according to the
   * requirement of select componnent
   * @param array - response from api
   **/
  setArray(array: any, id: string, label: string) {
    const temp: any[] = [];
    for (const item of array) {
      temp.push({
        'value': item[id],
        'label': item[label],
      });
    }
    return temp;
  }
  /**
   * Patch or fill the values in form
   * @param formgroup The form name
   * @param data The data to be filled in form
   */
  patchForm(formgroup: FormGroup, data: ICampaign) {
    //
  }
  /**
   * Add response in object
   * @param newOb object you want to add
   * @param oldOb in this new object values will be added
   */
  addObject(newOb: any, oldOb: any) {
    for (const key of Object.keys(newOb)) {
      oldOb[key] = newOb[key];
    }
    return oldOb;
  }

  /**Sort array */
  sortArray(array: any) {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
  sortArrayOfBject(array: any, key: any) {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
  /**navigate with type */
  navigateToForm(param: any, type: any, id: any) {
    this.router.navigate(
      [this.startAFrUrls['non-medical'], param],
      { queryParams: { did: id, type }, queryParamsHandling: 'merge' }
    );
  }

  /**navigate with type */
  navigateToMedicalForm(param: any, type: any, id: any) {
    this.router.navigate(
      [this.startAFrUrls['medical'], param],
      { queryParams: { did: id }, queryParamsHandling: 'merge' }
    );
  }

  /**
   * Set the complete path of the image
   * comes from server
   * @param dataArray Data object of Campign draft
   * @param mediaType Media type of the campaign
   */
  setImagePath(dataArray: ICampaign, mediaType: string) {
    for (const item of dataArray.media) {
      if (item.display_type_1 === mediaType) {
        return environment.APP.IMAGE_DOMAIN + item.path + item.file_name;
      }
    }
  }
  /**
   * Get Initials of name
   * But only two initials
   * @param name Name
   */
  getInitialsOfName(name: string) {
    if (name) {
      const data = name ? name.split(' ') : [];
      if (data.length > 1) {
        return data[0].charAt(0) + data[1].charAt(0);
      } else {
        return data[0].charAt(0).trim();
      }
    }
  }
  /**
   * Capitalize the every first latter of
   * given string
   * @param name Given String
   */
  capitalizeFirstLatter(name: string) {
    if (name) {
      const data: any = name.trim().split(' ');
      for (const index of Object.keys(data)) {
        data[index] = data[index].charAt(0).toUpperCase() + data[index].substring(1);
      }
      return data.join(' ');
    } else {
      return name;
    }
  }
  /**
   * Check fundraiser status is completed or not
   * @param status Status number of the fundraiser
   * @param endDate End date of the fundraiser
   */
  checkFundraiserEnded(status: any, endDate: any) {
    if (endDate) {
      const daysLeft = this.dayjsPipe.transform(endDate, 'diffInDays');
      if (daysLeft < 0) {
        return true;
      }
    }
    switch (status) {
      // Fundraiser Active
      case 1:
        return false;
      // Fundraiser Expired
      case 4:
        return true;
      // Fundraiser Active
      case 5:
        return false;
    }
  }
  /**Check if fundraiser made the
   * succesfull campaign
   * @param amount Set amount of the fundraiser
   * @param raised How raised for the fundraiser
   */
  checkIfSuccessfullyFunded(amount: number, raised: number) {
    if (amount && raised) {
      return raised / amount > 0.6 ? true : false;
    } else {
      return false;
    }
  }
  /**Set title of the page */
  setPageTitle(name: string) {
    const title = this.capitalizeFirstLatter(name);
    this.titleService.setTitle(`${title} - Ketto`);
  }
  /**Get youtuve idd from its url */
  getYouTubeId(url: string) {
    const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const match = url.match(regEx);
    if (match && match[1] && match[1].length === 11) {
      return match[1];
    }
  }
  /**Detect the browser */
  detectBrowser(): String {
    if (this.isBrowser) {
      const userAgent = window.navigator.userAgent;
      const browser = userAgent.match('Chrome|Firefox|Safari|NetScape|Opera|MSIE');
      return browser ? browser[0] : '';
    } else {
      return '';
    }
  }
  /**
  * Get device type
  */
  isMobile() {
    if (this.isBrowser) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      }
    }
    return false;
  }
  /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
  getMobileOperatingSystem() {
    if (this.isBrowser) {
      const userAgent = navigator.userAgent || navigator.vendor;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
      }
      return 'unknown';
    }
  }
  /**
   * Get the type of the user visting the campaign page
   */
  getUserType(campainerId: number, enityId: number, userId: number) {
    switch (true) {
      case campainerId === userId:
        return 'Campaigner';
      case enityId === userId:
        return 'NGO';
      default:
        return 'Visitor';
    }
  }
  /**get campaign type from id */
  getCampaignTypeFromId(id: number) {
    switch (id) {
      case 20:
        return 'personal';
      case 48:
        return 'ngo';
      case 49:
        return 'creative';
      case 149:
        return 'ad';
    }
  }
  /**Returns URL params in object form */
  getUrlParams() {
    if (typeof document !== 'undefined') {
      const search = document.location.search.substring(1);
      return search.split('&').reduce(function (prev: any, curr) {
        if (curr) {
          const p = curr.split('=');
          prev[p[0]] = decodeURIComponent(p?.[1]) || '';
        }
        return prev;
      }, {});
    }
  }
  navigateToQueryParam(key: string, value: string) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
      relativeTo: this.actRoute,
      queryParams: {
        [key]: value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: true
      // do not trigger navigation
    });
  }

  zenDeskLoadedCB() {
    if (this.isBrowser && this.isMobile()) {

      (<any>window).zE('webWidget:on', 'open', () => {
        this.setBodyUnscrollable(true);
      });

      (<any>window).zE('webWidget:on', 'close', () => {
        this.setBodyUnscrollable(false);
      });

    }
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }


  setBodyUnscrollable(value: any) {
    if (value && this.isBrowser) {
      this.bodyScrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
      document.body.style.overflow = (value) ? 'hidden' : '';
      document.body.style.position = (value) ? 'fixed' : '';
      document.body.style.left = (value) ? '0' : '';
      document.body.style.right = (value) ? '0' : '';
      document.body.style.top = (value) ? - this.bodyScrollTop + 'px' : '';
    }
  }

  /**Get from cookiee */
  getCookie(name: string) {
    if (this.isBrowser) {
      const value = '; ' + document.cookie;
      const parts: any = value.split('; ' + name + '=');
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    }
  }
  /**Get document referrer */
  getReferrer() {
    if (this.isBrowser) {
      return document.referrer;
    } else {
      return '';
    }
  }
  moveMedicalCauseToTop(personalCauses: any) {
    // Move medical cause to top of list
    // find the current index of 'Medical' cause;
    const medicalIndex = personalCauses.findIndex((cause: any) => cause.info_1 === 'Medical' || cause.label === 'Medical');
    const medicalCause = personalCauses[medicalIndex];
    if (medicalIndex > 0) {
      // using splice to remove elements from the array, starting at
      // the identified index, and affecting 1 element(s):
      personalCauses.splice(medicalIndex, 1);
      // putting the 'Medical' object back in the array:
      personalCauses.unshift(medicalCause);
    }
    return personalCauses;
  }

  /**Scroll page to the top */
  scrollToTop(b?, offset?) {
    if (this.isBrowser) {
      window.scrollTo({ top: offset || 0, behavior: b || 'smooth' });
    }
  }

  getPhoneWithExt(data: any) {
    let phone = '';
    if (data.extension) {
      phone = '+' + data.extension;
    }
    if (data.phone_1) {
      phone += data.phone_1;
    }
    return phone;
  }

  getDateInIos(date: any) {
    if (!date) { return new Date(); }
    const arr = date.split(/[- :]/);
    const dt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return dt;
  }

  activateVWOTest(customUrl: String) {
    if (this.isBrowser) {
      window['VWO'] = window['VWO'] || [];
      window['VWO'].push(['activate', {
        customUrl
      }]);
    }
  }

  /**Get utm parameters */
  getUTMs() {
    const utmsInUrl = this.getUrlParams();
    const utmsInCookie = this.cookie.getDecodedCookies('k_utm');
    if (utmsInUrl && utmsInUrl.hasOwnProperty('utm_source')) {
      return utmsInUrl;
    } else if (utmsInCookie && utmsInCookie.hasOwnProperty('utm_source')) {
      return utmsInCookie;
    } else {
      return {};
    }
  }

  getUTMonly(returnType?: 'url_string' | ''): any {
    const parmas = this.getUTMs();
    // tslint:disable-next-line: max-line-length
    const utmParams = Object.keys(parmas).filter(key => key.toString().toLocaleLowerCase().match('utm')).reduce((cur, key) => Object.assign(cur, { [key]: parmas[key] }), {});
    if (returnType === 'url_string') {
      return Object.keys(utmParams).length ? this.getUrlQueryStringFromObject(utmParams) : '';
    } else {
      return utmParams;
    }
  }

  /*Skip system events for certain campaigns specified in variable service*/
  skipSystemEvents() {
    const nonSysEventsCampaigns = ['/zerodha', '/dream11-for-india', '/inthistogether', '/oyocare-dfy'];
    let temp = false;
    nonSysEventsCampaigns.forEach((item) => {
      if (this.isBrowser && window.location.pathname.includes(item)) {
        temp = true;
      }
    });
    return temp;
  }
  /** Get city from ip */
  getCityFromIp() {
    const iplocation = this.storage.getFromSession('iplocation');
    if (iplocation && iplocation.city_name) {
      return iplocation.city_name;
    }
    return '';
  }

  /** Scroll into view smoothly */
  scrollIntoViewSmoothly(id: any, top?: any) {
    if (this.isBrowser) {
      const elRef = document.getElementById(id);
      if (elRef) {
        window.scrollTo({
          top: elRef.offsetTop - (top || 20),
          behavior: 'smooth'
        });
      }
    }
  }

  /** Scroll Into View Without scrollTo Method */
  scrollInto(id: string) {
    if (this.isBrowser) {
      const elRef = document.getElementById(id);
      if (elRef) {
        elRef?.scrollIntoView({
          behavior: 'smooth',
          // inline: 'start'
        });
      }
    }
  }

  /** Scroll into view smoothly by class*/
  scrollIntoViewSmoothlyByClass(class_name: string, el_pos = 0, top = 20, behavior?: any) {
    if (this.isBrowser) {
      const el = document.getElementsByClassName(class_name);
      if (el.length) {
        if (!el[el_pos].id) { el[el_pos].id = 'scroll-id'; }
        window.scrollTo({
          top: (document.getElementById(el[el_pos].id)?.offsetTop || 0) - top,
          behavior: behavior || 'smooth'
        });
      }
    }
  }

  setBasicInfo(basicInfo: any, beneficary: any) {
    const data = { basicInfo: {}, beneficiary: {} } as any;
    if (basicInfo?.length) {
      for (const item of basicInfo) {
        data.basicInfo[item.info_type] = item.info_1 || '';
      }
      if (beneficary) {
        data.beneficiary = beneficary;
      } else if (data.basicInfo.beneficiary_name) {
        data.beneficiary['full_name'] = data.basicInfo.beneficiary_name;
        data.beneficiary['relation'] = data.basicInfo.beneficiary_relation;
      } else {
        data.beneficiary = undefined;
      }
      return data;
    }
  }

  addCssToGlobal(link: string) {
    if (typeof document !== 'undefined') {
      const _el = document.createElement('link');
      _el.href = link;
      _el.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(_el);
    }
  }

  formatAmount(value: any, currency: any) {
    if (this.isBrowser && value && currency) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(value);
    }
  }

  isWithin5Days(nextPaymentDate: string): boolean {
    if (nextPaymentDate) {
      const [datePart, timePart] = nextPaymentDate.split(" ");
      const [year, month, day] = datePart.split("-").map(Number);
      const [hour, minute, second] = timePart.split(":").map(Number);
  
      // Create a Date object with parsed components. Month is 0-based in JavaScript.
      const targetDate = new Date(year, month - 1, day, hour, minute, second);
      const today = new Date();
  
      // Check if targetDate is a valid date
      if (isNaN(targetDate.getTime())) {
          console.error("Invalid date format for nextPaymentDate");
          return false;
      }
  
      // Calculate the difference in days
      const diffTime = targetDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
      return diffDays >= 0 && diffDays <= 5;
    } else {
      return false;
    }
  }

  /** Convert the color hex code to rgba format
   *  @param hex: Hex code of any color
   */
  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  /**
   * Gets prize amount
   * @param currency currency
   * @returns prize amount
   */
  public getPrizeAmount(currency: string): number {
    switch (currency) {
      case 'INR': case 'RS': return 300;
      case 'USD': case 'AED': return 60;
      case 'EUR': case 'GBP': case 'SAR': case 'SGD': return 30;
      default: return 0;
    }
  }


  /**
  * get filtered object by value
  */
  filterObject(object: any, filterString: string) {
    const filteredObj: any = {};
    for (const key in object) {
      if (object[key].toLowerCase().indexOf(filterString.toLowerCase()) > -1) {
        filteredObj[key] = object[key];
      }
    }
    return filteredObj;
  }

  checkNestedObj(obj: any, key: string) {
    return key.split('.').reduce((o, x) => (typeof o === 'undefined' || o === null) ? o : o[x], obj);
  }

  isIOSDevice() {
    if (this.isBrowser) {
      return /iPhone Simulator|iPad Simulator|iPod Simulator|iPad|iPhone|iPod/.test(navigator.userAgent);
    }
  }

  isAppleDevice() {
    if (this.isBrowser) {
      return /iPhone Simulator|iPad Simulator|iPod Simulator|iPad|iPhone|iPod|Mac/.test(navigator.userAgent);
    }
  }

  /**
  * get calculated sip amount based on total for specific months
  */
  calcSipAmountPerMonth(amount: number, months = 12) {
    const sNumber = (amount / months).toFixed().toString();
    let output: any;
    let out: any;
    for (let i = 0, len = sNumber.length; i < len; i++) {
      if (i < sNumber.length - 2) {
        output = `${output || ''}${sNumber.charAt(i)}`;
      } else {
        out = `${out || ''}${sNumber.charAt(i)}`;
      }
    }
    if (+out >= 25 && +out < 75) {
      output = `${output || ''}50`;
    } else if (+out >= 75 && +out <= 99) {
      output = `${+output + 1}00`;
    } else if (+out >= 0 && +out < 25) {
      output = `${output}00`;
    }
    return +output;
  }

  /** Get currency from ip location */
  getCurrencyFromCode(code: string) {
    const currency = this.currencies.find(item => item.code === code);
    if (currency) {
      return currency;
    }
    if (this.europeanCountries.includes(code)) {
      return this.currencies.find(item => item.code === 'EUR');
    } else if (this.gulfCountries.includes(code)) {
      return this.currencies.find(item => item.code === 'SAR');
    } else {
      return this.currencies.find(item => item.code === 'US');
    }
  }

  getUrlQueryStringFromObject(qParamObject: any) {
    if (this.isBrowser) {
      return new URLSearchParams(qParamObject).toString();
    }
  }

  getCurrencyObj(currency: string) {
    return this.currencies.find((item) => item.currency === currency);
  }

  setCurrency(currency: string) {
    this.currency.next(currency);
    this.storage.addSessionData('currency', currency);
  }

  // remove empty value attributes from object
  removeEmptyFromObject(obj: any) {
    // tslint:disable-next-line: max-line-length
    return Object.entries(obj).reduce((o: any, [key, value]) => (value === '' || value === null || value === undefined || value === 'undefined' ? o : (o[key] = value, o)), {});
  }

  blitzEventHandler(eventName: any) {
    if (this.isBrowser) {
      this.scriptLoaderService.loadScript('blitzlamaScript', '', true);
      const interval = setInterval(() => {
        if (window['blitz']) {
          blitz('triggerEvent', eventName);
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  createAbandonedCart(amt?: number, currency?: string) {
    const abandonedObj: any = { showAbandonedCart: true };
    const qParams = this.actRoute.snapshot.queryParams;
    for (const key in qParams) {
      if (Object.prototype.hasOwnProperty.call(qParams, key)) {
        abandonedObj[key] = qParams[key];
      }
    }

    if (!abandonedObj['amt']) {
      abandonedObj['amt'] = amt;
    }
    abandonedObj['amt'] = +abandonedObj['amt'];

    if (!abandonedObj['currency']) {
      abandonedObj['currency'] = currency || 'INR';
    }
    this.storage.check('abandonedCart', abandonedObj);
    this.storage.delete('abandonedCartOtd');
  }

  redirectWithUTM(redirectTo: string, paramObj?: object) {
    const qParams: any = this.actRoute.snapshot.queryParams;
    const utmObj: any = {
      ...qParams, ...paramObj
    };
    if (!utmObj?.utm_source) {
      utmObj.utm_source = 'internal_Ketto';
    }
    if (qParams?.utm_content) {
      utmObj.utm_content = qParams?.utm_content;
    }
    this.router.navigate([redirectTo], { queryParams: utmObj });
  }

  checkForActiveSip(subs: any) {
    if (!subs) {
      return 0;
    }
    return Object.keys(subs).map(key => !key.match('memorial') ? subs[key].status_flag : null).sort()[0] || 0;
  }

  toTitleCase(data: any) {
    if (typeof data === 'string') {
      return data.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    } else if (typeof data === 'object') {
      let new_str = '';
      for (const key in data) {
        new_str = new_str + (new_str ? '-' : '') + data[key].toLowerCase().replace(/\b\w/g, (s: any) => s.toUpperCase());
      }
      return new_str;
    }
    return data;
  }

  numberToComaString(num: any) {
    const n: any = num;
    if (typeof num === 'number') {
      num = parseInt(n, 10).toLocaleString('en-IN').split('.')[0];
    }
    return num;
  }

  arrayMovePosition(arr: any, key: any, value: any, new_index: any) {
    const old_index = arr.findIndex(function (a: any) { return a[key] == value; });
    if (old_index >= 0) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }
    return arr;
  }

  isApp() {
    return this.cookie.getCookie('platform');
  }

  objValuesToLowerCase(data: any) {
    if (typeof data === 'object') {
      if (Array.isArray(data)) {
        data = data.map(obj => obj = this.objValuesToLowerCase(obj));
      } else {
        Object.keys(data).map(key => {
          if (typeof data[key] === 'string') {
            data[key] = this.objValuesToLowerCase(data?.[key]);
          }
        });
      }
    } else if (typeof data === 'string') {
      data = data?.toLowerCase();
    }
    return data;
  }

  objToUrlString(data: any, param_key?: string) {
    if (typeof data === 'object') {
      let new_str = '';
      for (const key in data) {
        if (typeof data[key] === 'object') {
          new_str = new_str + (new_str ? '&' : '') + this.objToUrlString(data[key], key);
        } else {
          new_str = new_str + (new_str ? '&' : '') + (param_key || key) + '=' + data[key];
        }
      }
      return new_str;
    }
    return data;
  }

  getLengthToMultiply(data: any) {
    if (typeof data === 'object') {
      return data?.length || 1;
    } else {
      return 1;
    }
  }

  arrayTostring(data: any, seperator?: string) {
    let str = '';
    if (Array.isArray(data)) {
      data.forEach((v, i) => { str = str + (str && i < data.length ? seperator || '' : '') + v });
    } else if (typeof data === 'string') {
      str = data;
    }
    return str;
  }

  arrayOfObjTostring(data: any, separator: string = '', join_with: string = ':'): any {
    if (Array.isArray(data)) {
      return data.map(obj => this.arrayOfObjTostring(obj, separator)).join(join_with);
    } else if (typeof data === 'object') {
      const values = Object.values(data).map(value => {
        if (typeof data === 'object') {
          return this.arrayOfObjTostring(value, separator);
        }
        return value;
      });
      return values.join(separator);
    } else {
      return String(data);
    }
  }

  jsonToString(data: any) {
    if (typeof data === 'object') {
      try {
        data = JSON.stringify(data);
      } catch (error) { }
    }
    return data;
  }

  stringToJson(str: any) {
    if (typeof str === 'string') {
      try {
        return JSON.parse(str);
      } catch (error) { }
    }
    return str;
  }

  getActiveSip(data) {
    try {
      if (typeof data === 'object') {
        return Object.keys(data);
      }
    } catch (e) { }
    return [];
  }

  getSipCauseArray(qParam: any) {
    let returnArray: any[] = [];
    const params = { ...qParam };
    const sip_amt = +params?.amt || +params?.amount || this.vars.min_sip_amounts[params?.currency || 'INR']
    if (params?.sip_cause_enc) {
      try {
        params['sip_cause'] = atob(params.sip_cause_enc);
      } catch (error) { }
    }
    if (!params?.sip_cause) {
      params['sip_cause'] = [{ sip_cause: this.vars.default_sip_cause, amount: sip_amt }];
    }
    params['sip_cause'] = this.objValuesToLowerCase(this.stringToJson(params.sip_cause));
    if (typeof params.sip_cause === 'string') {
      params['sip_cause'] = [{ sip_cause: params.sip_cause, amount: sip_amt }];
    }
    returnArray = params['sip_cause'];
    return returnArray;
  }

  getPartnerPlan(qParam?: any) {
    let plan = qParam?.plan?.toLocaleLowerCase() || 'care';
    this.getSipCauseArray(qParam).forEach(val => {
      if (val.sip_cause === 'insurance' && val?.plan) {
        plan = val.plan;
      }
    });
    return plan;
  }

  setSelectedInsurance(data: { vendor?: string, frequency?: string, plan?: string, plan_id?: number, currency?: string }, qParam?) {
    let vendor = data?.vendor || qParam?.vendor || this.vars.selected_insurance?.vendor || 'bima';
    let plan = data?.plan?.toLocaleLowerCase() || qParam?.plan?.toLocaleLowerCase() || this.vars.selected_insurance?.plan || 'care';
    let frequency = data?.frequency || qParam?.frequency || this.vars.selected_insurance?.frequency || 'yearly';
    let currency = data?.currency || this.vars.selected_insurance?.currency || 'INR';
    const availableVendors = Object.keys(this.vars.insurance_vendors);
    if (!availableVendors.includes(vendor)) {
      vendor = availableVendors[0];
    }
    const availableFrequency = Object.keys(this.vars.insurance_vendors[vendor]);
    if (!availableFrequency.includes(frequency)) {
      frequency = availableFrequency[0];
    }
    const availablePlans = Object.keys(this.vars.insurance_vendors[vendor][frequency]);
    if (!availablePlans.includes(plan)) {
      plan = availablePlans[0];
    }
    const availableCurrency = Object.keys(this.vars.insurance_vendors[vendor][frequency][plan]);
    if (!availableCurrency.includes(currency)) {
      currency = availableCurrency[0];
    }
    if (!availablePlans.includes(plan)) {
      plan = availablePlans[0];
    }
    this.vars.selected_insurance = {
      vendor: vendor,
      plan: plan,
      plan_id: this.vars.insurance_vendors[vendor][frequency][plan]?.plan_id || null,
      plan_text: this.vars.insurance_plans_text[plan],
      frequency: frequency,
      plan_amount: this.vars.insurance_vendors[vendor][frequency][plan][currency],
      currency: currency
    }
    return this.vars.selected_insurance;
  }

  setInsuranceVendor(vendor, vendorData, frequency = 'yearly') {
    return new Promise((resolve) => {
      try {
        this.vars.insurancePlans.forEach(p => {
          if (this.vars.insurance_vendors[vendor][frequency]?.[p]) {
            this.vars.insurance_vendors[vendor][frequency][p] = {
              INR: vendorData?.healthfirst?.policies?.plans?.[p]?.yearly?.amount + (this.vars.selectedIllness?.illnessAmount || 0),
              plan_id: +vendorData?.healthfirst?.policies?.plans?.[p]?.yearly?.planId
            };
          }
        });
        this.setSelectedInsurance({});
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  }

  getCriticalIllnessAmount(params: { person: number | string, cover: number | string }) {
    return new Promise((resolve) => {
      let illnessAmount = 0;
      if (params?.person || params?.cover) {
        try {
          illnessAmount = this.vars.criticalIllnessPlans[params.person + ' person'][params.cover + ' lakh'];
        } catch (error) {
          params = { person: 1, cover: 1 };
          illnessAmount = this.vars.criticalIllnessPlans[params.person + ' person'][params.cover + ' lakh'];
        }
        this.vars.selectedIllness = {
          person: +params.person,
          cover: params.cover + ' lakh',
          cover_count: +params.cover,
          illnessAmount: illnessAmount
        };
      }
      resolve(illnessAmount);
    });
  }

  getToken() {
    const user = this.getUser();
    return user?.token || false;
  }

  getUser() {
    if (this.vars.isVariableLogin) {
      return this.vars.varLoginData.user;
    } else {
      return this.storage.get('user');
    }
  }

  getUserData(): IUser {
    if (this.vars.isVariableLogin) {
      return this.vars.varLoginData.userdata;
    } else {
      return this.storage.get('userdata');
    }
  }

  isValidDate(dateStr) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateStr)) {
      return false;
    }
    const dateObject = new Date(dateStr);
    return !isNaN(dateObject.getTime()) && dateObject.toISOString().slice(0, 10) === dateStr;
  }

  setUser(user) {
    if (this.vars.isVariableLogin) {
      this.vars.varLoginData.user = user;
    } else {
      this.storage.check('user', user);
    }
    return true;
  }

  setFrequentVisitCampaign(id){
    let freqVisitCampaigns = this.storage.get('freqVisitCampaigns') || [];
    let newFilteredArr = freqVisitCampaigns?.filter(campaignId => {
      return campaignId !== id;
    })
    freqVisitCampaigns = newFilteredArr;
    freqVisitCampaigns?.unshift(id);
    // Ensure the array length does not exceed 5 Campaigns Id's
    if (freqVisitCampaigns?.length > 5) {
      freqVisitCampaigns?.pop();
    }
    this.storage.check("freqVisitCampaigns", freqVisitCampaigns);
  }

  getFrequentVisitedCampaigns(){
    const freqVisitCampaigns = this.storage.get('freqVisitCampaigns');
    return freqVisitCampaigns || [];
  }

  setUserData(userdata: IUser) {
    if (userdata?.email?.match(this.vars.dummyEmailExtn)) {
      userdata.isDummyEmail = true;
      this.vars.isDummyEmail = true;
    } else {
      this.vars.isDummyEmail = false;
    }
    if (this.vars.isVariableLogin) {
      this.vars.varLoginData.userdata = userdata;
    } else {
      this.storage.check('userdata', userdata);
    }
    return true;
  }

  setUtmCookie() {
    if (typeof document !== 'undefined') {
      const utms = this.getUrlParams();
      if (!utms?.hasOwnProperty('utm_source')) {
        return;
      }
      utms.created_date = new Date().toISOString();
      const utmSource = utms?.utm_source?.toLowerCase();

      if (utmSource?.match('_calling')) {
        this.cookie.setCookie('_telecaling', `${JSON.stringify(utms)}`, 7);

      } else if (utmSource?.match('_ct|_clevertap')) {
        this.cookie.setCookie('_clevertap', `${JSON.stringify(utms)}`, 7);

      } else if (utmSource?.match('external_')) {
        this.cookie.setCookie('_ad', `${JSON.stringify(utms)}`, 7);
      }
    }
  }

  stripHtml(htmlString: string): string {
    if (typeof window !== 'undefined') {
      const divEl = document.createElement('div');
      divEl.innerHTML = htmlString;
      return divEl?.textContent || divEl?.innerText || '';
    }
  }

  refreshPage() {
    if (this.isBrowser) {
      window.location.reload();
    }
  }

  checkHfSurvey() {
    return new Promise((resolve, reject) => {
      let hfSurvey = this.storage.get('hfSurvey');
      if (!hfSurvey) {
        hfSurvey = this.storage.getFromSession('hfSurvey');
      }
      this.vars.hfSurveyStatus = hfSurvey?.opened ? 'opened' : hfSurvey?.closed ? 'closed' : hfSurvey?.done ? 'done' : '';
      resolve(true);
    });
  }

  daysPassedSince(dateString) {
    const givenDate = new Date(dateString).getTime();  // Convert string to date
    const currentDate = new Date().getTime();          // Get the current date
    
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - givenDate;
    
    // Convert the time difference to days
    const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysPassed;
  }
  
  getPercentage(required: number, raised: number, round = true): number {
    let perc: number = (raised / required) * 100;
    if (round) {
      perc = Math.round(perc);
    }
    return perc;
  }

  getPercent(required: number, percentage: number, round = true): number {
    let perc: number = (required * percentage) / 100;
    if (round) {
      perc = Math.round(perc);
    }
    return perc;
  }

  // removeQueryParam(key: string) {
  //   const currentUrl = this.router.url.split('?')[0];
  //   const queryParams = { ...this.router.getCurrentNavigation()?.extractedUrl.queryParams };
  //   delete queryParams[key];
  //   this.router.navigate([currentUrl], {
  //     queryParams: queryParams,
  //     replaceUrl: true,
  //   });
  // }
}
